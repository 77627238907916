body {
  margin: 0;
  --sjs-font-questiontitle-family: 'Aeonik Medium', 'Roboto Light', 'Roboto' sans-serif;
  --font-family: 'Aeonik Medium', 'Roboto Light', 'Roboto', sans-serif;
  font-family: 'Aeonik Medium', 'Roboto Light', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  width: 100%;
  position: fixed;
}

body {
  height: 100%;
  width: 100%;
  --body-actual-width: 100svw;
  --body-actual-height: 100svh;
  position: fixed;
  overflow: hidden;
  animation: blur 13s;
}

#root {
  width: 100%;
  height: 100%;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
}

.extended-view {
  background: white;
  height: max-content;
  width: max-content;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes blur {
  0% {
    filter: blur(7px);
  }
  100% {
    filter: blur(0px);
  }
}

.box {
  text-decoration: underline;
  background: #d9d8d8;
  border: 1px solid;
}

.box-shadow {
  box-shadow: 5px 5px;
}
