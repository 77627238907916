.home {
  width: 100%;
  height: 100%;
  position: relative;
}

.home-row-1 {
  margin: 0;
  background-color: rgb(130 34 255 / 22%);
}

.home-row-2 {
  background-color: #ff00ff75;
}

.home-row-3 {
  background-color: #ff00ffa8;
}

.home-row-4 {
  background-color: #00ffff5c;
  text-align: right;
}

.home-link-5 {
  top: 63%;
  left: 20%;
  z-index: 16;
}

.home-link-6 {
  top: 70%;
  left: 40%;
  z-index: 16;
}

.greetings {
  position: absolute;
  width: 100%;
  bottom: 3px;
  right: 2px;
  text-align: right;
}

@media (prefers-reduced-motion: no-preference) {
  .home-image-container {
    //animation: home-image-spin infinite 500s linear;
  }

  .home-image-link {
    animation: home-image-spin-compensate infinite 500s linear;
  }
}

@keyframes home-image-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes home-image-spin-compensate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}


.home-title {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  outline: none;
  text-align: center;
  background-color: transparent;
}

.home-title-s {
  font-family: 'Horizon Outline', serif;
  font-size: 15rem;
}
