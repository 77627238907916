.border {
  animation: 4s glitch-image infinite;
  overflow: hidden;
  width: 100%;
  height: 100%;

  > div {
    height: 33%;
    line-height: 50%;
    width: 33%;
  }

  &-text {
    font-size: 50%;
    color: white;
    font-family: 'Horizon Outline', serif;
    text-wrap: normal;
    white-space: normal;
    text-rendering: optimizeSpeed;

    &-#{1} {
      color: black;
      z-index: 1000;
      background-color: white;
    }

    &-#{2} {
      color: white;
      background-color: black;
    }

    &-#{3} {
      color: black;
      z-index: 1000;
      background-color: white;
    }

  }

  &-horizontal {
    z-index: 2;

    &-inverted {
      writing-mode: rl;
      z-index: 2;
      transform: rotate(180deg);
    }
  }

  &-vertical {
    writing-mode: vertical-rl;
    z-index: 1;
    &-inverted {
      writing-mode: vertical-lr;
      transform: rotate(180deg) scaleX(-1) scaleY(-1);
      z-index: 1;

      > div {
        transform: scaleX(-1);
      }
    }

  }
}
