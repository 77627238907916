.exposition-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    height: 100%;
    max-width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.exposition-background-video{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.exposition {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &-art {
    width: 100%;
    box-shadow: 2px 2px 2px 2px;

    &-container {
      width: 90%;
      position: relative;

      > video {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}

.exposition-note {
  font-family: 'Horizon Elements', serif;
  color: black;
  background-color: white;
  box-shadow: 2px 2px 2px 2px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(100%, 0);
  padding: 10px;
  margin: -10px -10px 10px 10px;
}
