h1, h2, h3, h4 {
  font-family: 'Horizon Outline', 'Roboto', sans-serif;
}

h1 {
  font-size: 2rem;
}

h2 {
  margin: 0;
  font-size: 1.25rem;
}

h3 {
  font-size: 1.5rem;
}

h3 span {
  font-size: 1.5rem;
}

