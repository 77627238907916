/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/roboto/Roboto-Medium.woff2') format('woff2'),
    url('/public/fonts/roboto/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/roboto/Roboto-BlackItalic.woff2') format('woff2'),
    url('/public/fonts/roboto/Roboto-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/roboto/Roboto-Black.woff2') format('woff2'),
    url('/public/fonts/roboto/Roboto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/roboto/Roboto-Bold.woff2') format('woff2'),
    url('/public/fonts/roboto/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'),
    url('/public/fonts/roboto/Roboto-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'),
    url('/public/fonts/roboto/Roboto-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/roboto/Roboto-LightItalic.woff2') format('woff2'),
    url('/public/fonts/roboto/Roboto-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/roboto/Roboto-Regular.woff2') format('woff2'),
    url('/public/fonts/roboto/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/roboto/Roboto-Italic.woff2') format('woff2'),
    url('/public/fonts/roboto/Roboto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/roboto/Roboto-ThinItalic.woff2') format('woff2'),
    url('/public/fonts/roboto/Roboto-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/roboto/Roboto-Thin.woff2') format('woff2'),
    url('/public/fonts/roboto/Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Roboto';
    src: url('/public/fonts/roboto/Roboto-Light.woff2') format('woff2'),
    url('/public/fonts/roboto/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Horizon Elementsfour';
    src: url('/public/fonts/horizon/HorizonElementsfour.woff2') format('woff2'),
    url('/public/fonts/horizon/HorizonElementsfour.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Horizon Outline';
    src: url('/public/fonts/horizon/HorizonOutline.woff2') format('woff2'),
    url('/public/fonts/horizon/HorizonOutline.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Horizon Linestwo';
    src: url('/public/fonts/horizon/HorizonLinestwo.woff2') format('woff2'),
    url('/public/fonts/horizon/HorizonLinestwo.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Horizon Elements';
    src: url('/public/fonts/horizon/HorizonElements.woff2') format('woff2'),
    url('/public/fonts/horizon/HorizonElements.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Horizon Elementsthree';
    src: url('/public/fonts/horizon/HorizonElementsthree.woff2') format('woff2'),
    url('/public/fonts/horizon/HorizonElementsthree.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Horizon';
    src: url('/public/fonts/horizon/HorizonRegular.woff2') format('woff2'),
    url('/public/fonts/horizon/HorizonRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Horizon Elementstwo';
    src: url('/public/fonts/horizon/HorizonElementstwo.woff2') format('woff2'),
    url('/public/fonts/horizon/HorizonElementstwo.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Horizon Outlinetwo';
    src: url('/public/fonts/horizon/HorizonOutlinetwo.woff2') format('woff2'),
    url('/public/fonts/horizon/HorizonOutlinetwo.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Horizon Lines';
    src: url('/public/fonts/horizon/HorizonLines.woff2') format('woff2'),
    url('/public/fonts/horizon/HorizonLines.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik';
    src: url('/public/fonts/aeonic/Aeonik-BoldItalic.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik Air';
    src: url('/public/fonts/aeonic/Aeonik-Air.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-Air.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik Air';
    src: url('/public/fonts/aeonic/Aeonik-AirItalic.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-AirItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik';
    src: url('/public/fonts/aeonic/Aeonik-Black.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik';
    src: url('/public/fonts/aeonic/Aeonik-Medium.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik';
    src: url('/public/fonts/aeonic/Aeonik-Light.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik';
    src: url('/public/fonts/aeonic/Aeonik-BlackItalic.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik';
    src: url('/public/fonts/aeonic/Aeonik-Bold.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik';
    src: url('/public/fonts/aeonic/Aeonik-RegularItalic.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik';
    src: url('/public/fonts/aeonic/Aeonik-MediumItalic.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik';
    src: url('/public/fonts/aeonic/Aeonik-Regular.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik';
    src: url('/public/fonts/aeonic/Aeonik-ThinItalic.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik';
    src: url('/public/fonts/aeonic/Aeonik-Thin.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/*noinspection CssUnknownTarget*/
@font-face {
    font-family: 'Aeonik';
    src: url('/public/fonts/aeonic/Aeonik-LightItalic.woff2') format('woff2'),
    url('/public/fonts/aeonic/Aeonik-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
